import React from 'react';

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    window.location.replace('https://picturemotion.com');
  }

  return null;
};

export default IndexPage;
